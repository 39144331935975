import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import { _st } from '@/softech';

export const bus = new Vue();

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    mxShowMessage(title, message = 'message', type = '' ) {
		bus.$emit('message', { title, message, type });
	},
    isEmpty( val ) {
      if( val == undefined || val == null || val.length == 0 )
        return true;

      return false;
    },
    formatMoney( n ) {
      return _st.toMoney(n);
    },
    toFixedNumber(x, n) {
      return _st.toFixedNumber(x, n);
    },
    intOnly(e) {
			// Allow: backspace, delete, tab, escape, enter and .
			if (
				[46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
				// Allow: Ctrl/cmd+A
				(e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
				// Allow: Ctrl/cmd+C
				(e.keyCode == 67 && (e.ctrlKey === true || e.metaKey === true)) ||
				// Allow: Ctrl/cmd+X
				(e.keyCode == 88 && (e.ctrlKey === true || e.metaKey === true)) ||
				// Allow: home, end, left, right
				(e.keyCode >= 35 && e.keyCode <= 39)
			) {
				// let it happen, don't do anything
				return;
			}

			// Ensure that it is a number and stop the keypress
			if (
				(e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
				(e.keyCode < 96 || e.keyCode > 105)
			) {
				e.preventDefault();
			}
		},
		numbersOnly(e) {
			// Allow: backspace, delete, tab, escape, enter and .
			if (
				[46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
				// Allow: Ctrl/cmd+A
				(e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
				// Allow: Ctrl/cmd+C
				(e.keyCode == 67 && (e.ctrlKey === true || e.metaKey === true)) ||
				// Allow: Ctrl/cmd+X
				(e.keyCode == 88 && (e.ctrlKey === true || e.metaKey === true)) ||
				// Allow: home, end, left, right
				(e.keyCode >= 35 && e.keyCode <= 39)
			) {
				// let it happen, don't do anything
				return;
			}

			// Ensure that it is a number and stop the keypress
			if (
				(e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
				(e.keyCode < 96 || e.keyCode > 105)
			) {
				e.preventDefault();
			}
		},
    getURLByName(name, url = window.location.href) {
        name = name.replace(/[[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
  }
});

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
