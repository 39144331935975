import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Rental Shop',
    component: () => import('../views/SelectPath.vue')
  },
  {
    path: '/event',
    name: 'Event',
    component: () => import('../views/Event.vue')
  },
  {
    path: '/event-outfit',
    name: 'Event Outfit',
    component: () => import('../views/EventOutfit.vue')
  },
  {
    path: '/height-weight',
    name: 'Customer Height & Weight',
    component: () => import('../views/HeightWeight.vue')
  },
  {
    path: '/use-date',
    name: 'Use date',
    component: () => import('../views/UseDate.vue')
  },
  {
    path: '/promo-code',
    name: 'Promo Code',
    component: () => import('../views/PromoCode.vue')
  },
  {
    path: '/package',
    name: 'Packages',
    component: () => import('../views/Packages.vue')
  },
  {
    path: '/customer-info',
    name: 'Customer Information',
    component: () => import('../views/PhoneNumber.vue')
  },
  {
    path: '/customer-details',
    name: 'Customer Details',
    component: () => import('../views/CustomerInfo.vue')
  },
  {
    path: '/customer-measurements',
    name: 'Customer Measurements',
    component: () => import('../views/Measurements.vue')
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('../views/Checkout.vue')
  },
  {
    path: '/thank-you/:foreignId',
    name: 'Thank you',
    props: true,
    component: () => import('../views/ThankYou.vue')
  },
  {
    path: '/event-status',
    name: 'Event Status',
    component: () => import('../views/EventStatus.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
